import Navbar from '../Components/Navbar';
import Contact from '../Components/Contact';

const Kontakt = () => {

  return (
    <>
    
    <div className="w-full center text-center">
        <Navbar/>
        <Contact/>
    </div>
    </>
  );
};

export default Kontakt;
