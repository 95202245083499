const Sigma=`
Sigma dzwoni na nowy domofon i pyta,
jaki kod do paczkomatu, bo wujek z dziadkiem Miłosza
przesłali paczkę ze świnią z Parlino.
Jednak zamiast odpowiedzi dostaje informację,
że musi wykonać skibidi i griddy,
aby odblokować "fanum tax."
Kai Cenat wspomniał, że w Ohio
edge jest na poziomie beta, więc no cap,
trzeba mieć hella rizz, bo grimace shake
mógłby się wylać. Diddy polecił wcześniej
chalant podejście, ale baby Gronk i Livy Dann
stwierdzili, że to wymaga alpha energii,
a nie goon ruchów. Sigma mewing,
bo gyat i rizz muszą się zsynchronizować na essa.`;
export default Sigma;