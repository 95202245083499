import React from "react";

const Generatorcomponent = () => {

    return(
    <>
        <div className="w-full h-full">
        <iframe
            src="https://bebekxdw.ct8.pl"
            title="External Site"
            className="w-full h-full"
            frameBorder="0"
            allowFullScreen
        ></iframe>
        </div>
    </>
    );
}
export default Generatorcomponent;